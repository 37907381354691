<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center mt4">
                <div>
                    <div class="b pv3"><router-link :to="{ name: 'LineBudget' }">&lt; Back to Budget</router-link></div>
                    <div class="b font24">Maintenance of Truck</div>
                </div>
                <div class="flex flex-wrap justify-between pv4">
                    <div class="budgetViewCard w-30">
                        <p class="viewCardHeader pb2">Total Budget</p>
                        <p class="b font16">NGN 100,000</p>
                    </div>
                    <div class="budgetViewCard w-30">
                        <p class="viewCardHeader pb2">Actual Budget</p>
                        <p class="b font16">NGN 100,000</p>
                    </div>
                    <div class="budgetViewCard w-30 flex justify-between">
                        <div>
                            <p class="viewCardHeader pb2">Balance</p>
                            <p class="b font16">NGN 100,000</p>
                        </div>
                        <div><img :src="require('@/assets/images/progressBar.svg')" /></div>
                    </div>
                </div>
                <div class="budgetViewCard">
                    <div class="flex flex-wrap justify-between">
                        <div>
                            <p class="viewCardHeader pb2">Total Budget</p>
                            <p class="b font16">NGN 100,000</p>
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="w-30">
                                <label for="lineStartDate" class="viewCardHeader">Start Date</label>
                                <input
                                    type="date"
                                    name="lineStartDate"
                                    class="input-style card-box mb3 mb0-l"
                                    placeholder="Search..."
                                    style="padding: 13px; width: 100%"
                                />
                            </div>
                            <div class="w-30">
                                <label for="lineEndDate" class="viewCardHeader">End Date</label>
                                <input
                                    type="date"
                                    name="lineEndDate"
                                    class="input-style card-box mb3 mb0-l"
                                    placeholder="Search..."
                                    style="padding: 13px; width: 100%"
                                />
                            </div>
                            <div class="w-30">
                                <label for="lineMonthly" class="viewCardHeader">Month</label>
                                <select name="lineMonthly" id="lineMonthly" style="padding: 13px; width: 100%">
                                    <option value="select">Select Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="pt3">
                        <img :src="require('@/assets/images/LineChart.svg')" />
                    </div>
                </div>
                <div class="margin24 pt4">
                    <div class="overflow-auto">
                        <table class="f6 w-100" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="py-8 billPad manageHeader pl2 fw6 tl p9 pr3">Number</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Date</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Type</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Customer</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Description</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Point</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Amount</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3">Action</th>
                                </tr>
                            </thead>
                            <tbody class="lh-copy">
                                <tr>
                                    <td class="pv2 pl2">10100</td>
                                    <td class="pv2 pl3">12/12/2023</td>
                                    <td class="pv2 pl3">Sales</td>
                                    <td class="pv2 pl3">Kara Eno</td>
                                    <td class="pv2 pl3">Buying of coco</td>
                                    <td class="pv2 pl3">Point</td>
                                    <td class="pv2 pl3">NGN35,000</td>
                                    <td class="pv2 pl3">
                                        <span class="pr1"><img :src="require('@/assets/images/eye.svg')" /></span>
                                        <span><img :src="require('@/assets/images/delete.svg')" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import AppWrapper from '@/layout/AppWrapper.vue'
export default {
    name: 'LineView',
    components: { AppWrapper },
    setup() {
        return {}
    },
}
</script>
<style scoped>
.font24 {
    font-size: 24px;
}
.font16 {
    font-size: 16px;
}
.budgetViewCard {
    border-radius: 8px;
    border: 1px solid #d0d9ff;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
}
.viewCardHeader {
    color: rgba(85, 89, 110, 0.75);
}
</style>
